
import { Options, Vue } from "vue-class-component";
import Layout from "@/components/Layout.vue";
import Title from "@/components/Title.vue";
import StatusCompletedForm from "@/components/Status/StatusCompleted/StatusCompletedForm.vue";

type QueryStatus = "qualified" | "completed" | "terminated" | "over_quota" | "error";
@Options({
  components: {
    Layout,
    Title,
    StatusCompletedForm,
  },
})
export default class SurveyStatus extends Vue {
  // DEPRECATED PAGE
  get status(): QueryStatus {
    return this.$route.query.status as QueryStatus;
  }

  get isCompletedStatus(): boolean {
    return this.status === "completed";
  }

  get component(): string | boolean {
    switch (this.status) {
      case "completed":
        return "StatusCompletedForm";

      default:
        return "StatusCompletedForm";
    }
  }

  mounted(): void {
    if (!this.status) {
      this.$router.push({
        name: "Error",
        query: {
          type: "notFound",
        },
      });
    }

    this.checkNavigationByStatus();
  }

  checkNavigationByStatus(): void | boolean {
    if (this.isCompletedStatus) return false;

    switch (this.status) {
      case "qualified":
        this.$router.push({
          name: "Qualified",
        });
        break;
      case "terminated":
        this.$router.push({
          name: "Terminated",
          params: {
            title: "title",
            message: "message2",
          },
        });
        break;
      case "over_quota":
        this.$router.push({
          name: "OverQuota",
          params: {
            title: "title",
            message: "message2",
          },
        });
        break;
      case "error":
      default:
        this.$router.push({
          name: "Error",
          query: {
            type: "notFound",
          },
        });
        break;
    }
  }
}
