
import { Contact } from "@/models/Contact";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    contact: Object,
  },
})
export default class ContactComponent extends Vue {
  contact: Contact;

  get fullName(): string {
    return `${this.contact.firstName} ${this.contact.lastName}`;
  }

  get contactInfo(): string {
    return `${this.contact.address}, ${this.contact.phoneNumber}`;
  }
}
