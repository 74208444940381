
import { Options, Vue } from "vue-class-component";
import Title from "@/components/Title.vue";
import StatusCompletedSuccessForm from "@/components/Status/StatusCompleted/StatusCompletedSuccessForm.vue";
import StatusCompletedLink from "@/components/Status/StatusCompleted/StatusCompletedLink.vue";

@Options({
  components: {
    Title,
    StatusCompletedSuccessForm,
    StatusCompletedLink,
  },
})
export default class StatusCompletedForm extends Vue {
  success = false;
  user: any = {
    id: "",
    firstName: "",
    lastName: "",
    address: "",
    email: "",
    phoneNumber: "",
  };

  onAction(): void {
    this.success = !this.success;
  }
}
