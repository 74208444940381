import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "invitation-template__in" }
const _hoisted_2 = { class: "invitation-template__in" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_ContactComponent = _resolveComponent("ContactComponent")!
  const _component_StatusCompletedLink = _resolveComponent("StatusCompletedLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Title, null, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t(`status.completed.title`)), 1)
      ]),
      desc: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t(`status.completed.message2`)), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_ContactComponent, { contact: _ctx.user }, null, 8, ["contact"]),
    _createVNode(_component_StatusCompletedLink)
  ], 64))
}