
import { Options, Vue } from "vue-class-component";
import { Contact } from "@/models/Contact";
import Title from "@/components/Title.vue";
import StatusCompletedLink from "@/components/Status/StatusCompleted/StatusCompletedLink.vue";
import ContactComponent from "@/components/Contact/index.vue";

@Options({
  components: {
    Title,
    StatusCompletedLink,
    ContactComponent,
  },
  props: {
    user: Object,
  },
})
export default class StatusCompletedSuccessForm extends Vue {
  user: Contact;
}
